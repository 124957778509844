.about-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.about-photo {
    min-width: 300px;
    width: 40%;
    height: 100%;
    margin-bottom: 20px;
    margin-right: 20px;
}

.about-info {
    width: 40%;
    min-width: 300px;
    padding: 0 20px;
}

@media screen and (max-width: 990px) {

    .about-content {
        justify-content: center;
    }
    
    .about-info {
        padding: 0;
    }

}

.about-detail-card {
    display: flex;
    flex-direction: row;
    border: solid 2px rgb(31, 28, 28);
    border-radius: 10px;
    padding: 10px 20px;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.about-detail-text {
    margin: 0 0 0 20px;
}

.about-text {
    font-size: 16px;
}

.about-poem {
    font-family: 'Fjalla One';
    font-size: 20px;
}