.Nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    margin-bottom: 40px;
}

.NavLeft {
    width: 40%;
    margin-left: 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.NavLeft > a {
    display: inherit;
    align-items: inherit;
    text-decoration: none;
}

.NavLeft > a:visited {
    color: inherit;
}

.name {
    font-family: 'Oswald';
    font-weight: 400;
    margin-left: 20px;
}

.NavRight {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 10%;
}

.NavRightSmall {
    display: none;
}


.Navlink {
    font-family: 'Oswald';
    font-weight: 500;
    text-decoration: none;
    color: rgba(31, 28, 28, 0.6);
    transition: color 0.3s ease;
    font-size: 3vw;
    margin-right: 40px;
}

@media screen and (max-width: 990px) {
    .NavLeft {
        width: 80%
    }

    .NavRight {
        display: none;
    }

    .NavRightSmall {
        align-items: center;
        display: flex;
        margin-right: 10%;
    }

    .Nav {
        margin-bottom: 20px;
    }

    .Navlink {     
        font-size: 40px;
    }
}

.Navlink:hover {
    color: rgba(31, 28, 28, 1);
}

.Active-Navlink {
    color: rgba(31, 28, 28, 1) !important;
}

.drawer-contents {
    display: flex;
    flex-direction: column;
}