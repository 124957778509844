.App {
  overflow: hidden;
  background: rgb(0,147,69);
  background: linear-gradient(45deg, #04d063 0%, #fdee21 100%);
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}