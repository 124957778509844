.contact-card-container {
    border-radius: 10px;
    padding: 5px 20px;
    font-size: min(18px, 4vw);
    background-color: rgb(31, 28, 28);
    color: white;
    mix-blend-mode: multiply; /* This makes the cutout text possible */
    margin: 10px 0 0 0;
    height: fit-content;
}


@media screen and (max-width: 990px) {
    .contact-methods {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.contact-card-title {
    font-family: 'Oswald';
    font-size: max(18px, 2vw);
    margin: 15px 70px 10px 0;
}


.contact-methods {
    
}

.contact-method {
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
    margin-left: 0;
    margin-bottom: 8px;
}

.contact-method-link {
    text-decoration: none;
    color: #FFFFFF;
}

.contact-method-name {
    margin: 0;
    margin-left: 10px;
    color: #bcbcbc;
    font-size: min(15px, 3vw);
}

.contact-method-text {
    margin: 0;
    margin-left: 10px;
    font-size: min(18px, 4vw);
}

.nigerd {}

